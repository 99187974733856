import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import CountUp from 'react-countup'

import CommonModal from '@components/common-modal/common-modal.component'
import SiteLink from '@components/SiteLink'
import { trackGa } from '@components/GoogleTracking'
import config from "@utils/config"

import download from '@assets/images/icons/icon-download.svg'

import './results.component.scss'

function countUpOrDownESS(props) {
  if (props.resultsHidden) {
    return <CountUp start={0} end={props.EssScore} delay={1} duration={5} useEasing={true} />
  } else {
    return <CountUp start={props.EssScore} end={0} delay={0} duration={4} useEasing={true} />
  }
}

function countUpOrDownSNS(props) {
  if (props.resultsHidden) {
    return <CountUp start={-50} end={props.SnsScore} delay={1} duration={5} useEasing={true} />
  } else {
    return <CountUp start={props.SnsScore} end={-50} delay={0} duration={4} useEasing={true} />
  }
}

class ResultsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.printResults = this.printResults.bind(this)
    this.handleCloseError = this.handleCloseError.bind(this)
    this.toggleEmailSection = this.toggleEmailSection.bind(this)
    this.openThanksModal = this.openThanksModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.allowContinue = this.allowContinue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.setupAutocomplete = this.setupAutocomplete.bind(this)

    this.state = {
      emailSubmitting: false,
      showErrorModal: false,
      errors: null,
      showMessageSent: false,
      showEmailArea: false,
      location: null,
      openThanksModal: false,
      isDownloadThanks: false,
      canContinue: false,
      email: '',
      zip: '',
      essEmailModal: true,
      stopSetup: false,
    }
  }

  handleInputChange(label, value) {
    this.setState({
      [label]: value,
    })
  }

  handleCloseModal() {
    this.setState({
      openThanksModal: false,
    })
  }

  handleCloseError() {
    this.setState({
      showErrorModal: false,
    })
  }

  toggleEmailSection(event) {
    event.preventDefault()

    this.setState((state) => {
      return {
        showEmailArea: !state.showEmailArea,
      }
    })
  }

  allowContinue() {
    this.setState({ canContinue: true })
    const submit = document.getElementById('submit-email')
    if (submit) {
      submit.disabled = false
    }
  }
  disableContinueButton() {
    this.setState({ canContinue: false })
  }

  getErrorsFormatted(errors) {
    if (errors) {
      if (typeof errors === 'string') {
        return <p className="errors">{errors}</p>
      } else {
        return (
          <ul className="errors">
            {Object.keys(errors).map((value, index) => {
              return <li key={index}>{errors[value]}</li>
            })}
          </ul>
        )
      }
    }
  }

  getSymptomResultsRequest(resultType, type) {
    if (type === 'print') {
      if (resultType == 'ess') {
        return {
          essScores: this.props.EssScores,
          essScore: this.props.EssScore,
        }
      } else
        return {
          snsScores: this.props.SnsScores,
          snsScore: this.props.SnsScore,
        }
    }
  }

  openThanksModal(type) {
    if (type === 'download') {
      this.setState({ isDownloadThanks: true })
    } else this.setState({ isDownloadThanks: false })
    this.setState({ openThanksModal: true })
  }

  printResults(resultType) {
    this.props.handleCloseModal()
    this.openThanksModal('download')

    window.open(
      `${config.REACT_APP_API_HOST}/symptom-screener.php?data=${encodeURIComponent(
        JSON.stringify(this.getSymptomResultsRequest(resultType, 'print'))
      )}`
    )
    this.setState({
      canContinue: false,
    })
  }

  messageSending(itSent) {
    return itSent ? 'message-sent-overlay' : 'display-none'
  }

  setupAutocomplete() {
    if (!this.props.startSetup) {
      this.props.setAutocompleteStartup()
      let input = document.getElementById('dl-zip-modal')
      if (input) {
        let options = {
          types: ['(regions)'],
          componentRestrictions: { country: 'us' },
        }
        let autocomplete = new window.google.maps.places.Autocomplete(input, options)

        new window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
          var place = autocomplete.getPlace()

          if (!place.geometry) {
            this.setState({
              location: null,
            })
          }

          this.setState({
            location: {
              lat: autocomplete.getPlace().geometry.location.lat(),
              lng: autocomplete.getPlace().geometry.location.lng(),
            },
          })
        })
      }
    }
  }

  componentDidUpdate() {
    const timer = setInterval(setupFunction, 500)
    const callAutoComplete = () => {
      this.setupAutocomplete()
    }
    function setupFunction() {
      const zip = document.getElementById('zip-input')
      if (zip) {
        callAutoComplete()
        clearInterval(timer)
      }
    }
  }

  render() {
    return (
      <div>
        {this.props.essCalculated | this.props.snsCalculated ? (
          <div className="results-card-wrapper post-calc">
            {this.props.essCalculated ? (
              <h2 className="card-title">
                The Epworth Sleepiness Scale <span>(ESS)</span>
              </h2>
            ) : (
              <h2 className="card-title">The Swiss Narcolepsy Scale <span>(SNS)</span></h2>
            )}

            <div className="results-card post-calc">
              {this.props.essCalculated ? (
                <div className="score-details">
                  <div className="score-value">
                    <h3 className="sub-title-bold">Your ESS score is:</h3>
                    <h5>{countUpOrDownESS(this.props)}</h5>
                    <p>
                      An ESS score &gt;10 suggests excessive daytime sleepiness.<sup>1</sup> All patients with excessive
                      daytime sleepiness should be evaluated by a sleep specialist.
                    </p>
                  </div>
                  <section className="score-chart-section">
                    <h3 className="sub-title-bold">
                      Interpreting ESS Scores<sup>1,4,*</sup>
                    </h3>
                    <div className="chart">
                      <div className="scale-details">
                        <div className="normal-levels">
                          <p>Normal levels of sleepiness</p>
                        </div>
                        <div className="excessive-levels">
                          <p>Excessive daytime sleepiness (EDS)</p>
                        </div>
                        <div className="high-levels">
                          <p>Suggests high level of EDS</p>
                        </div>
                      </div>
                      <div className="scale-values">
                        <h6 className="first-value">0</h6>
                        <h6 className="second-value">10</h6>
                        <h6 className="third-value">16</h6>
                        <h6 className="fourth-value">24</h6>
                      </div>
                      <div className="scale-line">
                        <div className="normal-line" />
                        <div className="excessive-line" />
                        <div className="high-line" />
                      </div>
                      <div className="ess-marker-holder">
                        <div className="ess-marker-verticle">
                          <FontAwesomeIcon icon="map-marker-alt" />
                        </div>
                        <div className="ess-marker-horizontal-md">
                          <FontAwesomeIcon icon="map-marker-alt" />
                        </div>
                        <div className="ess-marker-horizontal-lg">
                          <FontAwesomeIcon icon="map-marker-alt" />
                        </div>
                      </div>
                    </div>
                    <div className="receive-results">
                      <p className="results-explainer">
                        Your patient’s scores are available as a PDF that includes the Narcolepsy Conversation Starter
                      </p>
                      <div className="results-buttons-wrapper">
                        <div
                          className="results-buttons"
                          onClick={() => {
                            this.props.showResultsModal('ess', 'download')
                            this.printResults('ess');
                            trackGa('narcolepsy symptom screener, click, download - ess')
                          }}
                        >
                          <div>Download Results</div>
                          <div className="arrow-rotate">
                            <img src={download} className="icon-download" alt="download arrow icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              ) : null}
              {this.props.snsCalculated ? (
                <div className="resluts-link-wrapper">
                  <div className="score-details sns-details">
                    <div className="score-value">
                      <h3 className="sub-title-bold">Your SNS score is:</h3>
                      <h5>{countUpOrDownSNS(this.props)}</h5>
                      <p>
                        An SNS calculated score that is &lt;0 is suggestive of narcolepsy with cataplexy.
                        <sup>2,6,7</sup> All patients with an SNS calculated score that is less than 0 should be
                        evaluated by a sleep specialist.
                      </p>
                    </div>
                    <section className="score-chart-section">
                      <h3 className="sub-title-bold">
                        Interpreting SNS Scores<sup>2,6,7,*</sup>
                      </h3>
                      <div className="chart">
                        <div className="scale-details">
                          <div className="suggests-level">
                            <p>Suggests narcolepsy with cataplexy</p>
                          </div>
                          <div className="not-suggestive-level">
                            <p>Not suggestive of narcolepsy with cataplexy</p>
                          </div>
                        </div>
                        <div className="sns-scale-values">
                          <h6 className="first-value">-50</h6>
                          <h6>-40</h6>
                          <h6>-30</h6>
                          <h6>-20</h6>
                          <h6>-10</h6>
                          <h6 className="six-value">0</h6>
                          <h6 className="six-value">6</h6>
                        </div>
                        <div className="scale-line">
                          <div className="ten-line" />
                          <div className="ten-line" />
                          <div className="ten-line" />
                          <div className="second-last-ten-line" />
                          <div className="last-ten" />
                          <div className="six-line" />
                          <div className="last-line" />
                        </div>
                        <div className="sns-marker-holder">
                          <div className="sns-marker-verticle">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </div>
                          <div className="sns-marker-horizontal-md">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </div>
                          <div className="sns-marker-horizontal-lg">
                            <FontAwesomeIcon icon="map-marker-alt" />
                          </div>
                        </div>
                      </div>
                      <div className="receive-results">
                        <p className="results-explainer">
                          Your patient’s scores are available as a PDF that includes the Narcolepsy Conversation Starter
                        </p>

                        <div className="results-buttons-wrapper">
                          <div
                            className="results-buttons"
                            onClick={() => {
                              this.props.showResultsModal('sns', 'download')
                              this.printResults('sns');
                              trackGa('narcolepsy symptom screener, click, download - sns')
                            }}
                          >
                            <div>Download Results</div>
                            <div className="arrow-rotate">
                              <img src={download} className="icon-download" alt="download arrow icon" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              ) : null}
            </div>
            <CommonModal isShowModal={this.state.showErrorModal}>
              <div className="body-content">
                <h2 className="title">Error</h2>
                <p className="content">A server error occurred while submitting your request.</p>
                {this.getErrorsFormatted(this.state.errors)}
                <div className="action">
                  <button className="button btn-primary" onClick={this.handleCloseError}>
                    Ok
                  </button>
                </div>
              </div>
            </CommonModal>
          </div>
        ) : null}
        <Modal
          className="content-modal base-modal-screener"
          show={this.state.openThanksModal}
          onHide={this.handleCloseModal}
        >
          <div className="modal-content-wrapper">
            <div className="close-modal-x" onClick={this.handleCloseModal}>
              X
            </div>
            <h2 className="modal-title">{this.state.isDownloadThanks ? 'Download Results' : 'Email Results'}</h2>
            <Modal.Body className="download-body">
              <p className="content">
                <b>
                  {this.state.isDownloadThanks
                    ? 'Thanks! Your download should begin in a new window.'
                    : 'Thanks! Your email has been sent.'}
                </b>
              </p>
              <div className="action">
                <a className="close-window" onClick={this.handleCloseModal}>
                  Close Window
                </a>
              </div>
            </Modal.Body>
          </div>
        </Modal>
        <div className="pediatriclink-wrapper">
          <SiteLink
            to="/pediatric-patients/screening/"
            className="ess-chad-link"
            tracking={`narcolepsy symptom screener, click, ess chad - ${
              this.props.essCalculated ? 'ess' : 'sns'
            } results cta`}
          >
            <p>Screen a pediatric patient</p>
            <FontAwesomeIcon icon={faAngleRight} />
          </SiteLink>
          <p>
            These screening tools are not intended to make a narcolepsy diagnosis or replace complete evaluation by a
            sleep specialist.
          </p>
        </div>
      </div>
    )
  }
}

ResultsComponent.propTypes = {
  calculateValues: PropTypes.func,
  EssScores: PropTypes.object,
  SnsScores: PropTypes.object,
  EssScore: PropTypes.number,
  SnsScore: PropTypes.number,
  snsCalculated: PropTypes.bool,
  essCalculated: PropTypes.bool,
  resultsHidden: PropTypes.bool,
  showResultsModal: PropTypes.func,
  isEssResults: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  startSetup: PropTypes.bool,
  setAutocompleteStartup: PropTypes.func,
  showEssDownloadModal: PropTypes.bool,
  showSnsDownloadModal: PropTypes.bool,
}

export default ResultsComponent
