import React, { Component } from 'react'
import PropTypes from 'prop-types'

import downArrow from '@assets/images/icons/md-arrow-dropdown.svg'

import './optional-info.component.scss'

class OptionalInformation extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <div className="optional-information">
        <h3>Optional Information</h3>
        <div className="optional-inputs">
          <div className="radio-gender-wrapper">
            <div className="radio-gender-section">
              <label className="optional-info-label gender">Gender:</label>
              <div className="radio-wrapper">
                <div className="radio male">
                  <label className="gender-label">Male</label>
                  <input
                    onClick={() => {
                      this.props.genderTracking('male')
                    }}
                    className="gender-radio"
                    type="radio"
                    value="option1"
                    name="gender"
                    id="male-box"
                  />
                  <label htmlFor="male-box" />
                </div>
                <div className="radio female">
                  <label className="gender-label">Female</label>
                  <input
                    onClick={() => {
                      this.props.genderTracking('female')
                    }}
                    className="gender-radio"
                    type="radio"
                    value="option2"
                    name="gender"
                    id="female-box"
                  />
                  <label htmlFor="female-box" />
                </div>
              </div>
            </div>
          </div>
          <div className="age-section">
            <label className="optional-info-label age">Age Range:</label>
            <select
              className="form-select"
              id="select"
              onChange={() => {
                var sel = document.getElementById('select')
                this.props.ageTracking(sel.value)
              }}
            >
              <option value="" hidden>
                Select
              </option>
              <option value="1">18-24</option>
              <option value="2">25-34</option>
              <option value="3">35-44</option>
              <option value="4">45-54</option>
              <option value="5">55 or older</option>
            </select>
            <div className="select-dropdown-icon">
              <img src={downArrow} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

OptionalInformation.propTypes = {
  genderTracking: PropTypes.func,
  ageTracking: PropTypes.func
}

export default OptionalInformation
