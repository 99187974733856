import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CheckboxComponent from '@components/checkbox/checkbox.component'
import './ess-screener.component.scss'

class EssScreener extends Component {
  constructor(props) {
    super(props)

    this.handleOptionsChange = this.handleOptionsChange.bind(this)
  }

  handleOptionsChange(changeEvent) {
    this.props.selectResponse(changeEvent)
  }

  render() {
    let essQuestions = [
      {
        label: <p><b>No chance</b>  of dozing</p>,
        number: 0
      },
      {
        label: <p><b>Slight Chance</b> of Dozing</p>,
        number: 1
      },
      {
        label: <p><b>Moderate Chance</b> of Dozing</p>,
        number: 2
      },
      {
        label: <p><b>High Chance</b> of Dozing</p>,
        number: 3
      }
    ]

    return (
      <div className="wide-card">
        <div className="quiz-header sns-quiz-header">
          <div className="response-headers">
            <h5><b>No chance</b> of Dozing</h5>
            <h5><b>Slight Chance</b> of Dozing</h5>
            <h5><b>Moderate Chance</b> of Dozing</h5>
            <h5><b>High Chance</b> of Dozing</h5>
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Sitting and reading</h6>
            <p>1 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map((item, index )=> (
              <CheckboxComponent
                key={index}
                number={item.number}
                radioGroup="ess1"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Watching TV</h6>
            <p>2 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map((item, index ) => (
              <CheckboxComponent
                key={index}
                number={item.number}
                radioGroup="ess2"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>
              Sitting inactive in a public place <span>(e.g., a theatre or a meeting)</span>
            </h6>
            <p>3 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map((item, index ) => (
              <CheckboxComponent
                key={index}
                number={item.number}
                radioGroup="ess3"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>As a passenger in a car for an hour without a break </h6>
            <p>4 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map((item, index ) => (
              <CheckboxComponent
                key={index}
                number={item.number}
                radioGroup="ess4"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Lying down to rest in the afternoon while circumstances permit</h6>
            <p>5 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map((item, index ) => (
              <CheckboxComponent
                key={index}
                number={item.number}
                radioGroup="ess5"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Sitting and talking to someone</h6>
            <p>6 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map((item, index ) => (
              <CheckboxComponent
                key={index}
                number={item.number}
                radioGroup="ess6"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>Sitting quietly after a lunch without alcohol</h6>
            <p>7 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map((item, index ) => (
              <CheckboxComponent
                key={index}
                number={item.number}
                radioGroup="ess7"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
        <div className="single-question">
          <div className="question-header">
            <h6>In a car or bus, while stopped for a few minutes in traffic</h6>
            <p>8 of 8</p>
          </div>
          <div className="answers">
            {essQuestions.map((item, index ) => (
              <CheckboxComponent
                key={index}
                number={item.number}
                radioGroup="ess8"
                quizGroup="EssScores"
                label={item.label}
                optionSelect={this.handleOptionsChange}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

EssScreener.propTypes = {
  selectResponse: PropTypes.func
}

export default EssScreener
