export const references = [
  {
    content: 'Johns MW. A new method for measuring daytime sleepiness: the Epworth sleepiness scale. ',
    source: 'Sleep. ',
    info: '1991;14(6):540-545.',
  },
  {
    content: 'Sturzenegger C, Bassetti CL. The clinical spectrum of narcolepsy with cataplexy: a reappraisal. ',
    source: 'J Sleep Res. ',
    info: '2004;13(4):395-406.',
  },
  {
    content:
      'Krahn LE, Hershner S, Loeding LD, et al. Quality measures for the care of patients with narcolepsy. ',
    source: 'J Clin Sleep Med. ',
    info: ' 2015;11(3):335-355. ',
  },
  {
    content:
      ' Johns M, Hocking B. Excessive daytime sleepiness: daytime sleepiness and sleep habits of Australian workers. ',
    source: ' Sleep. ',
    info: '1997;20(10):844-849.',
  },
  {
    content:
      ' Sturzenegger C, Baumann CR, Kallweit U, Lammers GJ, Bassetti CL. Swiss Narcolepsy Scale: a valid tool for the identification of hypocretin-1 deficient patients with narcolepsy. ',
    source: 'J Sleep Res. ',
    info: '2014;23(suppl 1):297.',
  },
  {
    content: 'Bassetti CL. Spectrum of narcolepsy. In: Baumann CR, Bassetti CL, Scammell TE, eds. ',
    source: 'Narcolepsy: Pathophysiology, Diagnosis, and Treatment. ',
    info: 'New York, NY: Springer Science+Business Media; 2011:309-319.',
  },
  {
    content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In: ',
    source: ' The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version. ',
    info: 'Darien, IL: American Academy of Sleep Medicine; 2014.',
  },
]