import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CheckboxComponent from '@components/checkbox/checkbox.component'
import './sns-screener.component.scss'

class SnsScreener extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleOptionsChange = this.handleOptionsChange.bind(this)
  }

  handleOptionsChange(changeEvent) {
    this.props.selectResponse(changeEvent)
  }

  render() {
    let snsQuestions = [
      {
        label: 'Never',
        number: 1
      },
      {
        label: <span>Rarely <span className="parentheses-sub">(less than once a month)</span></span>,
        number: 2
      },
      {
        label: <span>Sometimes <span className="parentheses-sub">(1-3 times a month)</span></span>,
        number: 3
      },
      {
        label: <span>Often <span className="parentheses-sub">(1-2 times a week)</span></span>,
        number: 4
      },
      {
        label: 'Almost always',
        number: 5
      }
    ]

    let snsQuestions2 = [
      {
        label: 'Never',
        number: 1
      },
      {
        label: 'I would like to, but cannot',
        number: 2
      },
      {
        label: '1-2 times a week',
        number: 3
      },
      {
        label: '3-5 times a week',
        number: 4
      },
      {
        label: 'Almost daily',
        number: 5
      }
    ]

    return (
      <div className="sns-quiz">
        <div className="wide-card">
          <div className="quiz-header">
            <h4> </h4>
            <div className="response-headers sns-rh">
              <h5>Never</h5>
              <h5>
                Rarely<br />
                <span>(less than once a month)</span>
              </h5>
              <h5>
                Sometimes<br />
                <span>(1-3 times a month)</span>
              </h5>
              <h5>
                Often<br />
                <span>(1-2 times a week)</span>
              </h5>
              <h5>Almost always</h5>
            </div>
          </div>
          <div className="single-question sns-q">
            <div className="question-header">
              <h6>How often are you unable to fall asleep?</h6>
              <p>1 of 5</p>
            </div>
            <div className="answers">
              {snsQuestions.map((item, i) => (
                <CheckboxComponent
                  key={`snsQ1Answers${i}`}
                  number={item.number}
                  radioGroup="sns1"
                  quizGroup="SnsScores"
                  label={item.label}
                  optionSelect={this.handleOptionsChange}
                />
              ))}
            </div>
          </div>
          <div className="single-question sns-q">
            <div className="question-header">
              <h6>How often do you feel bad or not well rested in the morning?</h6>
              <p>2 of 5</p>
            </div>
            <div className="answers">
              {snsQuestions.map((item, i) => (
                <CheckboxComponent
                  key={`snsQ2Answers${i}`}
                  number={item.number}
                  radioGroup="sns2"
                  quizGroup="SnsScores"
                  label={item.label}
                  optionSelect={this.handleOptionsChange}
                />
              ))}
            </div>
          </div>
          <div className="quiz-header inner-header">
            <h4> </h4>
            <div className="response-headers sns-rh">
              <h5>Never</h5>
              <h5>I would like to, but cannot</h5>
              <h5>
                1-2 times<br /> a week
              </h5>
              <h5>
                3-5 times<br /> a week
              </h5>
              <h5>Almost daily</h5>
            </div>
          </div>
          <div className="single-question sns-q">
            <div className="question-header">
              <h6>How often do you take a nap during the day?</h6>
              <p>3 of 5</p>
            </div>
            <div className="answers">
              {snsQuestions2.map((item, i) => (
                <CheckboxComponent
                key={`snsQ3Answers${i}`}
                  number={item.number}
                  radioGroup="sns3"
                  quizGroup="SnsScores"
                  label={item.label}
                  optionSelect={this.handleOptionsChange}
                />
              ))}
            </div>
          </div>
          <div className="quiz-header inner-header">
            <h4> </h4>
            <div className="response-headers sns-rh">
              <h5>Never</h5>
              <h5>
                Rarely<br />
                <span>(less than once a month)</span>
              </h5>
              <h5>
                Sometimes<br />
                <span>(1-3 times a month)</span>
              </h5>
              <h5>
                Often<br />
                <span>(1-2 times a week)</span>
              </h5>
              <h5>Almost always</h5>
            </div>
          </div>
          <div className="single-question sns-q">
            <div className="question-header">
              <h6>
                How often have you experienced weak knees/buckling of the knees during emotions like laughing,
                happiness, or anger?
              </h6>
              <p>4 of 5</p>
            </div>
            <div className="answers">
              {snsQuestions.map((item, i) => (
                <CheckboxComponent
                key={`snsQ4Answers${i}`}
                  number={item.number}
                  radioGroup="sns4"
                  quizGroup="SnsScores"
                  label={item.label}
                  optionSelect={this.handleOptionsChange}
                />
              ))}
            </div>
          </div>
          <div className="single-question sns-q">
            <div className="question-header">
              <h6>
                How often have you experienced sagging of the jaw during emotions like laughing, happiness, or anger?
              </h6>
              <p>5 of 5</p>
            </div>
            <div className="answers">
              {snsQuestions.map((item, i) => (
                <CheckboxComponent
                  key={`snsQ5Answers${i}`}
                  number={item.number}
                  radioGroup="sns5"
                  quizGroup="SnsScores"
                  label={item.label}
                  optionSelect={this.handleOptionsChange}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SnsScreener.propTypes = {
  selectResponse: PropTypes.func
}

export default SnsScreener
